<template>
	<LoginLayout
		:form-title="formTitle"
		portal-name="Customer Service Portal"
	>
		<template #form>
			<validation-observer
				v-if="!showOtpLinkSentScreen"
				#default="{ handleSubmit }"
				class="cassie-vertical-sm"
			>
				<TextField
					v-model="username"
					outlined
					label="Username *"
					rules="required"
					placeholder="Please enter your email address"
				/>
				<PasswordField
					v-model="password"
					label="Password *"
				/>
				<v-card-actions class="pa-0">
					<TextButton @click="navigateToForgottenPassword">
						Forgotten Password?
					</TextButton>
					<v-spacer />
					<PrimaryActionButton
						:disabled="tryingToLogIn"
						:loading="tryingToLogIn"
						@click="handleSubmit(tryToLogIn)"
					>
						Login
					</PrimaryActionButton>
				</v-card-actions>
			</validation-observer>
			<div v-else>
				<p>
					You have been emailed a link to grant you access to Cassie Customer Service Portal. Click the link in the email which will open a new tab in your browser.
				</p>
				<p>
					You can close this tab
				</p>
			</div>
		</template>
	</LoginLayout>
</template>

<script>
import { mapActions } from 'vuex'
import appConfig from '../../../app.config.json'
import LoginLayout from '../../../../../shared/layouts/login-layout.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
import PasswordField from '../../../../../shared/components/password-field.vue'
import { FORGOTTEN_PASSWORD, DATA_SUBJECT_SEARCH } from '../../../router/route-names.js'
import TextField from '../../../../../shared/components/text-field.vue'

export default {
	components: { TextField, LoginLayout, PrimaryActionButton, TextButton, PasswordField },
	page: {
		title: 'Log in',
		meta: [{ name: 'description', content: `Log in to ${appConfig.title}` }]
	},
	data () {
		return {
			username: '',
			password: '',
			showPassword: false,
			authError: null,
			tryingToLogIn: false,
			showOtpLinkSentScreen: false,
			formTitle: 'Sign In'
		}
	},
	methods: {
		...mapActions('auth', ['logIn', 'logOut']),
		// Try to log the user in with the username
		// and password they provided.
		tryToLogIn () {
			this.tryingToLogIn = true
			// Reset the authError if it existed.
			this.authError = null
			this.logIn({
				username: this.username,
				password: this.password
			})
				.then(response => {
					this.tryingToLogIn = false
					if (response != null) {
						this.showOtpLinkSentScreen = response.otpLoginRequired
						if (this.showOtpLinkSentScreen) {
							this.formTitle = 'Link has been sent'
							return
						}
					}
					// Redirect to the originally requested page, or to the home page
					this.$router.push(
						this.$route.query.redirectFrom || { name: DATA_SUBJECT_SEARCH }
					)
				})
				.catch(error => {
					if (error.message === 'Account locked out') {
						this.$router.push({ name: 'account-locked-out' })
					} else if (error.message === '403') {
						this.$router.push({ name: 'direct-login-not-allowed' })
					}
					this.tryingToLogIn = false
					this.authError = error
				})
		},
		navigateToForgottenPassword () {
			this.$router.push({ name: FORGOTTEN_PASSWORD })
		}
	}
}
</script>
